import React, { useRef, useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/table";
import { account_columns } from "../../../../components/tables/tableheader";
import { accountSchema } from "../../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../../components/Pagination";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal";
import { ArrowDownCircleIcon, ArrowUpCircleIcon, CircleStackIcon, PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../../ApiServices/MasterServices";
import WhopServices from "../../../../ApiServices/WhopServices";
import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { utils, writeFile, read } from 'xlsx';
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../../helper/commonHelper";
import Select from "react-select";
import Editor from '@monaco-editor/react';

const team = [];

function AccountList() {
  const pages = [{ title: "Account List", href: "/account", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lam_id, setAccountid] = useState("");
  const [selectedLicId, setSelectedLicId] = useState({ value: '', label: '' });
  const [selectedUserId, setSelectedUserId] = useState({ value: '', label: '' });
  const [selectedProductId, setSelectedProductId] = useState({ value: '', label: '' });
  const [selectedPreAccountId, setSelectedPreAccountId] = useState({ value: '', label: '' });
  const [licenseList, setLicenseList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [preAccountList, setPreAccountList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [configData, setConfigData] = useState("");
  const [configDataManual, setConfigDataManual] = useState("");
  const [currentConfigUser, setCurrentConfigUser] = useState(null);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    lam_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    lam_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  const editorRef = useRef(null);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
    // const socket = new WebSocket('ws://localhost:8007?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE3MTYwMjE1MTMsImV4cCI6MTcyMTIwNTUxM30.HbM3A8nkVyw6_tC4VzwJLfMaDvq5gdvbZig5vX8SjTI');

    // socket.onopen = () => {
    //   console.log('WebSocket connected');
    // };

    // socket.onmessage = (event) => {
    //   console.log('Message from server:', "PS");
    // };

    // socket.onerror = (error) => {
    //   console.error('WebSocket error:', error);
    // };

    // socket.onclose = () => {
    //   console.log('WebSocket connection closed');
    // };

  }, []);

  useEffect(() => {
    document.title = "Korvato •  Accounts";
    getAllAccounts(3 + '&filter=no');
    getAllUser(1);
  }, []);

  const getAllAccounts = async (payload) => {
    setIsLoading(true);
    try {
      const res = await WhopServices.getAllWhopAccount(payload)
      if (res.status) {
        setAccountList(res.data.data);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }
  const getAllLicense = async (value) => {
    let payload = `1&fk_wp_user_id=${value}&fk_wp_prod_id=${selectedProductId?.value}`;
    try {
      const res = await WhopServices.getAllWhopLicense(payload)
      if (res.status) {
        setLicenseList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllLicense")
      toast.error("Fatal Error")
    }
  }
  const getAllProduct = async (value) => {
    let payload = `1&fk_wp_user_id=${value}`;
    try {
      const res = await WhopServices.getAllWhopProduct(payload)
      if (res.status) {
        setProductList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllLicense")
      toast.error("Fatal Error")
    }
  }
  const getAllUser = async (payload) => {
    try {
      const res = await WhopServices.getAllWhopUser(payload)
      if (res.status) {
        setUserList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllUser")
      toast.error("Fatal Error")
    }
  }

  //const onStatusUpdate = (lam_id, lam_is_banned) => {
  const onStatusUpdate = (pk_id, acc_status, type) => {

    let payload = {
      table_name: "license_account_map",
      table_field: "lam_id",
      table_field_val: pk_id,
      table_status_field: type, //"lam_is_banned",
      table_status_val: acc_status ? false : true,
      table_text: "Account",
      deleted_by_key: "lam_updated_by",
      delete_active_txt: "Acc Status" //acc_status ? " Un-Baned" : " Ban",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllAccounts(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  let initialValues = {
    lam_id: "",
    fk_wp_lic_id: "",
    fk_wp_user_id: "",
    fk_wp_prod_id: "",
    fk_previous_account_id: "",
    user_name: "",
    prod_name: "",
    lam_account_no: "",
    lam_is_banned: false,
    lam_is_active: true,
  };

  const [formAccount, setFormAccount] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    setSelectedLicId('');
    setSelectedPreAccountId('')
    setSelectedProductId('')
    setSelectedUserId('')
    getAllLicense(obj?.fk_wp_user_id);
    values.fk_wp_lic_id = "";
    values.fk_wp_user_id = "";
    values.fk_wp_prod_id = "";
    getAllLicense(obj?.fk_wp_user_id);
    if ((type === "edit", id)) {
      setAccountid(id);
      let initialValues = {
        lam_id: obj.lam_id,
        fk_wp_lic_id: obj.fk_wp_lic_id,
        fk_wp_user_id: obj.fk_wp_user_id,
        fk_wp_prod_id: obj.fk_wp_prod_id,
        fk_previous_account_id: obj.lam_id,
        lam_account_no: obj.lam_account_no,
        lam_is_banned: obj.lam_is_banned,
        lam_is_active: obj.lam_is_active,
      };

      setFormAccount(initialValues);
      if (Array.isArray(licenseList) && licenseList.length > 0) {
        let license = licenseList.filter(v => v.value == initialValues.fk_wp_lic_id)
        if (license.length > 0) {
          initialValues.prod_name = license[0].master_whop_product_relation?.prod_name;
          setSelectedLicId({ value: license[0].value, label: license[0].label })
        }
      }
      if (Array.isArray(userList) && userList.length > 0) {
        let user = userList.filter(v => v.value == initialValues.fk_wp_user_id)
        if (user.length > 0) {
          initialValues.user_name = user[0].user_name;
          setSelectedUserId({ value: user[0].value, label: user[0].label })
        }
      }
      if (Array.isArray(productList) && productList.length > 0) {
        let product = productList.filter(v => v.value == initialValues.fk_wp_user_id)
        if (product.length > 0) {
          initialValues.prod_name = product[0].prod_name;
          setSelectedProductId({ value: product[0].value, label: product[0].label })
        }
      }
    } else {
      setAccountid("");
      setFormAccount(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (pk_id, acc_status, type) => {
    let title = "Are you sure you want to modify?";
    if (type == "lam_is_stopped_trading") {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Re-Start Trading" : "Stop Trading") + " this Account?";
    } else if (type == "lam_is_closed_all_trade") {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Open All Trading" : "Close All Trading") + " this Account?";
    } else {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Un-Ban (Revoke)" : "Ban") + " this Account?";
    }
    setConfirmationModal({ pk_id, acc_status, type, status: true, title });
  };

  const onDeleteOpenSection = (lam_id, lam_is_deleted) => {
    setRemoveConfirmationModal({ lam_id, lam_is_deleted, status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formAccount,
      validationSchema: accountSchema,
      onSubmit: async (values, action) => {

        let body = {
          lam_id: lam_id,
          fk_wp_lic_id: values.fk_wp_lic_id,
          fk_wp_user_id: values.fk_wp_user_id,
          fk_wp_prod_id: values.fk_wp_prod_id,
          fk_previous_account_id: values.fk_previous_account_id,
          lam_account_no: values.lam_account_no,
          lam_is_banned: values.lam_is_banned,
          lam_is_active: true,
          licAccountArr: [],
        };

        if (body.lam_account_no != undefined || body.lam_account_no != null || body.lam_account_no != "") {
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          setAccountList([]);
          setIsLoading(true);
          let { data, status, message } = await WhopServices.SaveWhopAccount(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllAccounts(3);
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 1000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllAccounts(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });

  const onDeleteAccount = async (lam_id) => {
    let { data, status } = await WhopServices.deleteWhopAccount({ lam_id })
    if (status === 200) {
      if (data.status) {

        toast.success(data.message);
        getAllAccounts(3);
      }
    } else {

      toast.error(data.message);
    }
    setRemoveConfirmationModal({ lam_id: null, status: false });
  };

  const filterUser = (user) => {
    const result = userList.filter(obj => obj?.label?.toLowerCase() === user?.toLowerCase());
    let resp = null;
    if (result.length > 0) {
      resp = result[0].value
    } else {
      resp = undefined;
    }
    return resp;
  }

  const filterLicense = (License) => {
    const result = licenseList.filter(obj => obj?.label?.toLowerCase() === License?.toLowerCase());
    let resp = null;
    if (result.length > 0) {
      resp = result[0].value
    } else {
      resp = undefined;
    }
    return resp;
  }
  const filterProduct = (product) => {
    const result = productList.filter(obj => obj?.label?.toLowerCase() === product?.toLowerCase());
    let resp = null;
    if (result.length > 0) {
      resp = result[0].value
    } else {
      resp = undefined;
    }
    return resp;
  }


  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("Account handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {

            rows.map((obj, index) => {
              if (obj.lam_account_no != undefined || obj.license_key != undefined) {
                const newObj = {
                  lam_account_no: obj.lam_account_no !== undefined ? obj.lam_account_no ? obj.lam_account_no.trim() : obj.lam_account_no.trim() : '',
                  fk_wp_lic_id: filterLicense(obj.license_name),
                  fk_wp_user_id: filterUser(obj.user_name),
                  fk_wp_prod_id: filterProduct(obj.prod_name),
                };

                newData.push(newObj);
              }

            });

            console.log("newArray: ", newData);
            const filteredArray = newData.filter(obj => obj.fk_wp_lic_id !== undefined && obj.fk_wp_user_id !== undefined && obj.fk_wp_prod_id !== undefined);
            console.log("filteredArray: ", filteredArray);
            if (newData.length > 0) {
              importAccount(newData);
            }

          } else {
            toast.success("Excel is empty")
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  const importAccount = (data) => {
    const payload = { excel_data: data }
    MasterServices.userExcelImport(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Account Imported Successfully");
          getAllAccounts(3);
        } else {
          toast.error(data.message)
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg)
      });
  }

  const handleExport = (excel_type) => {
    if (!accountList || accountList.length === 0) {
      toast.error("Account list is empty!");
      return;
    }
    if (accountList?.length > 0) {
      let newSheetdata = [];
      accountList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          lam_id: obj?.lam_id || '-',
          fk_wp_user_id: obj?.fk_wp_user_id || '-',
          user_name: obj?.master_whop_user_relation?.user_name || '-',
          user_email: obj?.master_whop_user_relation?.user_email || '-',
          fk_wp_lic_id: obj?.fk_wp_lic_id || '-',
          license_key: obj?.master_whop_license_relation?.license_key || '-',
          lic_affiliate_username: obj?.master_whop_license_relation?.lic_affiliate_username || '-',
          fk_wp_prod_id: obj?.fk_wp_prod_id || '-',
          prod_name: obj?.master_whop_product_relation?.prod_name || '-',
          lam_account_no: obj?.lam_account_no || '-',
          lam_created_date: moment(obj?.lam_created_date).format("DD-MM-YYYY") || '-',
          Status: obj?.lam_is_active == true ? 'Active' : 'In-Active' || '-',
          BannStatus: obj?.lam_is_banned == true ? 'Banned' : '' || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'Account_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
      const headings = [['SN', 'Account ID', 'User ID', 'User Name', 'User Email', 'License ID', 'License Key', 'License Affiliate Username', 'Product ID', 'Product Name', 'Account No', 'Created Date', 'Status']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  const goToMetrix = (data) => {
    navigate(data);
  }

  const addParamConfig = (data) => {
    
    setCurrentConfigUser(data);
    console.log("setCurrentConfigUser=>",data)
    if (data && data.mt_user_account && data.mt_user_account[0]) {
      // alert(data?.mt_user_account[0]?.mua_acc_param_is_json)
      /* if (data?.mt_user_account[0]?.mua_acc_param_is_json) {
        setConfigData(JSON.stringify(JSON.parse(data.mt_user_account[0]?.mua_acc_param_config)));
      } else {
        setConfigData(JSON.stringify(data.mt_user_account[0]?.mua_acc_param_config));
      } */

        if (data && data.mt_user_account && data.mt_user_account[0] && data.mt_user_account[0]?.mua_acc_param_config_manual && data.mt_user_account[0]?.mua_acc_param_config_manual != null) {
          if (data?.mt_user_account[0]?.mua_acc_param_is_json) {
            setConfigDataManual(JSON.stringify(JSON.parse(data.mt_user_account[0]?.mua_acc_param_config_manual)));
          } else {
            setConfigDataManual(JSON.stringify(data.mt_user_account[0]?.mua_acc_param_config_manual));
          }
        }

        if (data && data.mt_user_account && data.mt_user_account[0] && data.mt_user_account[0]?.mua_acc_param_config && data.mt_user_account[0]?.mua_acc_param_config != null) {
          if (data?.mt_user_account[0]?.mua_acc_param_is_json) {
            setConfigData(JSON.stringify(JSON.parse(data.mt_user_account[0]?.mua_acc_param_config)));
          } else {
            setConfigData(JSON.stringify(data.mt_user_account[0]?.mua_acc_param_config));
          }
        }
    }else{
      toast.error("Sorry Here No Account No Attached ...");
      return;
    }
    setOpen(true);
  }

  const updateUserConfig = async (e) => {

    /* if(configData != null){}else{} */
    if (!currentConfigUser) {
      toast.error("Something went wrong...");
      return;
    }

    let { data, status } = await WhopServices.updateConfigData({ mua_id: currentConfigUser?.mt_user_account[0]?.mua_id, data: configDataManual });
    if (status === 200) {
      if (data.status) {
        toast.success(data.message);
        getAllAccounts(3 + '&filter=no');
      }
      setOpen(false);
    } else {
      toast.error(data.message);
    }

  }

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    //console.log("editor.getValue()=>", editor.getValue())
    editor.onDidChangeModelContent(() => {
      // Update configData state when the editor content changes
      setConfigDataManual(editor.getValue());
    });
    /* if (editorRef.current.getValue() != null && editorRef.current.getValue() != "") {
      // setConfigData(editorRef.current.getValue());
      setConfigData(editorRef.current.getValue());
      console.log(editorRef.current.getValue());
    } */
  }

  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={confirmationModal?.title ? confirmationModal?.title : "Are you sure you want to modify settings?"}
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.pk_id,
            confirmationModal.acc_status,
            confirmationModal.type
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.lam_is_deleted
            ? " Undo"
            : "Delete") +
          " this Account?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteAccount(removeConfirmationModal.lam_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Account List</h1>
      </div>
      {/* flex use beelow class */}
      <div className="mt-4 hidden hide">
        {allowedActions.filter((data) => data.permission_id == 52)?.length >
          0 ? (
          <Link
            onClick={() => handleDrawer("add", "", {})}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
          >
            <PlusCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
            Add Account
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 52)?.length >
          0 ? (<Link
            to={`../account/0`}
          >
            <button
              className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-2"
              size="default"
              variant="outlined"
            >
              <CircleStackIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
              Add Multiple Account<span className="sr-only">, </span>
            </button>
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 57)?.length > 0 && accountList?.length > 0 ? (
          <Link
            to={"#"}
            onClick={() => handleExport('xlsx')}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-2"
          >
            <ArrowDownCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
            Download Excel
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 58)?.length > 0 ? (
          <label htmlFor="file-upload" className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-3 py-1 text-xs font-medium text-white shadow-sm hffff  ml-2 cursor-pointer transition duration-300 ease-in-out">
            <ArrowUpCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
            Upload Account
            <input id="file-upload" type="file" className="hidden" onChange={handleImport} />
          </label>
        ) : null}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={account_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
            goToMetrix,
            addParamConfig,
            setConfigData
          })}
          data={accountList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {lam_id ? "Update" : "Add"} Account
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md  text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div>
                                <label
                                  htmlFor="user_name"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  User Email
                                  <span className="text-red-600">*</span>
                                </label>
                              </div>
                              {/* {userList && userList.length > 0 ? ( */}
                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <Select
                                  name="user_name"
                                  id="wp_user_id"
                                  // menuPlacement="top"
                                  placeholder={
                                    <span>
                                      Select User Email
                                      <span className="text-red-600">*</span>
                                    </span>
                                  }
                                  value={selectedUserId}
                                  onChange={(e) => {
                                    console.log(e);
                                    values.fk_wp_user_id = e.value;
                                    values.user_name = e.user_name;
                                    getAllProduct(e.value);
                                    setSelectedUserId(e);
                                  }}
                                  components={animatedComponents}
                                  options={
                                    userList ? userList
                                      : []
                                  }
                                  classNamePrefix="select"
                                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                />

                                {errors.fk_wp_user_id && touched.fk_wp_user_id ? (
                                  <div className="text-sm text-red-600">
                                    {errors.fk_wp_user_id.replace("fk_wp_user_id", "User")}
                                  </div>
                                ) : null}
                              </div>
                              {/* ) : null} */}
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="user_name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    User Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    disabled={true}
                                    value={values.user_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="User Name"
                                    name="user_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_name &&
                                    touched.user_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_name.replace("user_name", "User Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="fk_wp_prod_id"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Product
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <Select
                                  name="prod_name"
                                  id="wp_prod_id"
                                  menuPlacement="top"
                                  placeholder={
                                    <span>
                                      Select Product
                                      <span className="text-red-600">*</span>
                                    </span>
                                  }
                                  value={selectedProductId}
                                  onChange={(e) => {
                                    setSelectedProductId(e);
                                    console.log(e);
                                    values.fk_wp_prod_id = e.value;
                                    getAllLicense(selectedUserId?.value);
                                    setSelectedLicId({ label: "", value: "" })
                                  }}
                                  components={animatedComponents}
                                  options={
                                    productList ? productList
                                      : []
                                  }
                                  classNamePrefix="select"
                                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                />

                                {errors.fk_wp_prod_id && touched.fk_wp_prod_id ? (
                                  <div className="text-sm text-red-600">
                                    {errors.fk_wp_prod_id.replace("fk_wp_prod_id", "Product")}
                                  </div>
                                ) : null}
                              </div>

                              {/* {licenseList && licenseList.length > 0 ? ( */}
                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="user_name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    License
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <Select
                                  name="license_key"
                                  id="wp_lic_id"
                                  menuPlacement="top"
                                  placeholder={
                                    <span>
                                      Select License
                                      <span className="text-red-600">*</span>
                                    </span>
                                  }
                                  value={selectedLicId}
                                  onChange={(e) => {
                                    values.fk_wp_lic_id = e.value;
                                    values.prod_name = e?.master_whop_product_relation?.prod_name;
                                    setSelectedLicId(e);
                                  }}
                                  components={animatedComponents}
                                  options={
                                    licenseList ? licenseList
                                      : []
                                  }
                                  classNamePrefix="select"
                                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                />

                                {errors.fk_wp_lic_id && touched.fk_wp_lic_id ? (
                                  <div className="text-sm text-red-600">
                                    {errors.fk_wp_lic_id.replace("fk_wp_lic_id", "License")}
                                  </div>
                                ) : null}
                              </div>
                              {/* ) : null} */}
                              {/* <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="prod_name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Product Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    disabled={true}
                                    value={values.prod_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Product Name"
                                    name="prod_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.prod_name &&
                                    touched.prod_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.prod_name.replace("prod_name", "Product Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div> */}
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="lam_account_no"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Account No
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.lam_account_no}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Account No"
                                    name="lam_account_no"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.lam_account_no &&
                                    touched.lam_account_no ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.lam_account_no.replace("lam_account_no", "Account No")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" &&
                                    "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex justify-center rounded-md bbt bg-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : "bg-cyan-700"
                              }`}
                          >
                            {lam_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Add Acc Config */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog className="relative z-10" onClose={setOpen}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          updateUserConfig(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                Parameter Module - Details
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                  onClick={() => setOpen(false)}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                            <div className="mt-1">
                              <p className="text-sm text-indigo-300">
                                Account: {currentConfigUser ? currentConfigUser.lam_account_no : "N/A"}
                              </p>
                              <p className="text-sm text-indigo-300">
                                Product: {currentConfigUser ? currentConfigUser.master_whop_license_relation?.master_whop_product_relation?.prod_name : "N/A"}
                              </p>
                              <p className="text-sm text-indigo-300">
                                Name: {currentConfigUser ? currentConfigUser.master_whop_user_relation?.user_name + " - " + currentConfigUser.master_whop_user_relation?.user_email : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                              <div className="space-y-6 pb-5 pt-6">
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Manual Config Data - (JSON/TEXT)
                                  </label>
                                  <div className="mt-2">
                                    <Editor
                                      height="70vh"
                                      defaultLanguage="json"
                                      defaultValue={configDataManual}
                                      onMount={handleEditorDidMount}
                                      className="border bg-slate-400"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* AUTO */}
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                              <div className="space-y-6 pb-5 pt-6">
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Para Config Group Data - AUTO
                                  </label>
                                  <div className="mt-2">
                                    <Editor
                                      options={{readOnly: true}}
                                      height="70vh"
                                      defaultLanguage="json"
                                      defaultValue={configData}
                                      className="border bg-slate-400"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            /* onClick={(e)=>{
                              updateUserConfig();
                            }} */
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default AccountList;