import React, { Fragment, useEffect, useState, useRef, useCallback } from "react";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast, ToastContainer } from "react-toast";
import moment from 'moment';
import Table from "../../../components/tables/table";
import { matrics_trade_history_columns } from "../../../components/tables/tableheader";
import { utils, writeFile } from 'xlsx';


const AccMetrixHistory = ({uid,name,accid}) => {

  const [tradeHistory, setTradeHistory] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isCustomPagination, setIsCustomPagination] = useState(true);
  const [customPageNo, setCustomPageNo] = useState(0);


  const getAllTradeHistory = useCallback(async (pageNo) => {
    console.log(pageNo)
   let PageCount = pageNo?.pageIndex?pageNo?.pageIndex:pageNo;
    if (!uid || !name || !accid) {
      console.error('uid, name, or accid is missing or invalid.');
      return;
    }
    let payload = `?uid=${uid}&name=${name}&accid=${accid}&page=${parseInt(PageCount)}`;
    try {
      const res = await MasterServices.getTradeHistory(payload);
      if (res.status) {
        setPageCount(res?.data?.data?.trade_history_pagination?.total_pages);
        setTradeHistory(res?.data?.data?.trade_history);
      }
    } catch (e) {
      console.log(e, "error in getAllMatrics");
      toast.error("Oops, Something went wrong...");
    }
  }, [customPageNo]);

  useEffect(() => {
    getAllTradeHistory(customPageNo);
    console.log("on useEffet >>", customPageNo);
    
  }, [customPageNo, getAllTradeHistory]);


  const handleExport = (excel_type) => {
    if (!tradeHistory || tradeHistory.length === 0) {
      toast.error("Trade History is empty!");
      return;
    }
    if (tradeHistory?.length > 0) {
      let newSheetdata = [];
      tradeHistory.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          mth_order_ticket: obj?.mth_order_ticket || '-',
          mth_order_open_time: obj?.mth_order_open_time || '-',
          mth_order_type: obj?.mth_order_type == 0 ? 'BUY' : 'SELL' || '-',
          mth_order_lots: obj?.mth_order_lots || '-',
          mth_order_symbol: obj?.mth_order_symbol || '-',
          mth_order_open_price: obj?.mth_order_open_price || '-',
          mth_order_close_price: obj?.mth_order_close_price || '-',
          mth_order_close_time: obj?.mth_order_close_time || '-',
          mth_order_sl: obj?.mth_order_sl || '-',
          mth_order_tp: obj?.mth_order_tp || '-',
          mth_order_profit: obj?.mth_order_profit || '-',
          mth_order_comm: obj?.mth_order_comm || '-',
          mth_order_swap: obj?.mth_order_swap || '-',
          mth_scp_sum: obj?.mth_scp_sum || '-',
        }
        newSheetdata.push(newData);
      });

      let currentDate = new Date();
      let fileName = 'trade_history_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
      const headings = [['SN', 'Ticket', 'Open Time', 'Type', 'Lots', 'Symbol', 'Open Price ($)', 'Close Price ($)', 'Close Time', 'SL', 'TP', 'Profit', 'Comission', 'Swap', 'Net PNL']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }


  const setCustomPageNoHandle = (e) => {
    console.log("setCustomPageNoHandle >> ",e);
    setCustomPageNo(e);
  }

  return (
    <>
      <Table
        columns={
          matrics_trade_history_columns({})
        }
        data={tradeHistory ? tradeHistory : []}
        is_toggle={true}
        isCustomPagination={isCustomPagination}
        // fetchData={getAllTradeHistory}
        fetchData={(page) => getAllTradeHistory(page)}
        pageCount={pageCount}
        customPageNo={customPageNo}
        setCustomPageNo={setCustomPageNoHandle}
      />
    </>
  )
}

export default AccMetrixHistory